<template>
  <div id="app">
     <router-view :validatingToken="validatingToken"/>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default({
  name: "App",
  computed: mapState([ 'user']),
  data: function() {
		return {
			validatingToken: false
		}
	},
})
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #120222 !important;
  overflow-x:auto;
}
</style>
