export default {
    createEvento:{ method: 'post', url: '/promoter/event'},
    createTicket:{ method: 'post', url: '/promoter/ticket'},
    createBebida:{ method: 'post', url: '/promoter/product'},


    deleteEvento: {method:'delete', url:'/promoter/event/{event_id}'},
    putEventos: { method: 'put', url: '/promoter/event/{event_id}'},
    getEventos: { method: 'get', url: '/promoter/event' },

    getMoney: { method: 'get', url: '/promoter/balance' },
    getProducts: { method: 'get', url: '/promoter/sellproducts/{event_id}' },
    getTickets: { method: 'get', url: '/promoter/selltickets/{event_id}' },

    deleteProducts: { method: 'delete', url: '/promoter/product/{product_id}' },
    getEditProducts: { method: 'get', url: '/promoter/product/{event_id}' },
    putEditProducts: { method: 'put', url: '/promoter/product/{product_id}' },
    
    deleteTickets: {method: 'delete', url: '/promoter/ticket/{ingresso_id}'},
    getEditTickets: { method: 'get', url: '/promoter/ticket/{event_id}' },
    putEditTickets: { method: 'put', url: '/promoter/ticket/{ingresso_id}' },

    createRestaurante: {method: 'post', url: '/promoter/restaurant'},
    getRestaurante: {method: 'get', url: '/promoter/restaurant'},
    deleteRestaurante: {method: 'delete', url: '/promoter/restaurant/{id}'},

    createCortesia: {method: 'post', url: '/promoter/cortesy?qnt={query}'},
    getCortesia: {method: 'get', url:'/promoter/cortesy/{event_id}'},
    deleteCortesia: {method: 'delete', url:'/promoter/cortesy/{cortesy_id}'},


    createPermuta: { method: 'post', url:'/promoter/exchange?qnt={query}'},
    getPermuta: {method: 'get', url:'/promoter/exchange/{event_id}'},
    recargaPermuta: { method: 'put', url: '/promoter/exchange/{event_id}?value={qtn_recarga}'},
    deletePermuta: {method: 'delete', url: '/promoter/exchange/{event_id}'}


}