import router from '@/router'
import { auth, http } from '@/server'
import {showSucess, showError} from '@/global'

export const ActionDoLogin = async ({ dispatch }, payload) => {
    auth.login.data = payload
    await http.request(auth.login)
    .then(async response => {
      if (response.status === 200) {
          showSucess('Usuário Logado!') // TESTE DE RESPOSTA
          dispatch('ActionSetToken', response.data.token)  
          dispatch('ActionSetRefreshToken', response.data.refreshToken)  
      
        if (router.currentRoute.name !== 'Eventos') {
          router.push({ name: 'Eventos' })
        }

        return Promise.sucess
      }else{
        Promise.reject(error)
        showError(data)
      }
    }).catch((err) => {
        Promise.reject(err)
        showError(err)
    })
}
export const ActionDoLogout = ({ dispatch }) => {
    dispatch('ActionSetToken', null)  
    dispatch('ActionSetRefreshToken', null)  

    if (router.currentRoute.name !== 'Login') {
      router.push({ name: 'Login' })
    }
}
export const ActionUpdateToken = async ({ dispatch }) => {
    await http.request(auth.updateToken).then( response => {
      if (response.status === 200) {
        dispatch('ActionSetToken', response.data)
        Loading.hide()
      }
    }).catch(() => {
      dispatch('ActionDoLogout')
      Loading.hide()
    })
}
export const ActionSetToken = ( {commit},token ) => {
  localStorage.setItem('token', JSON.stringify(token));
}
export const ActionSetRefreshToken = ({commit},refreshToken) => {
  localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
}
