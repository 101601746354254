<template>
<v-app  id="home">
    <div class="home">
     <div class="d-flex justify-center">
          <v-img 
           class="ma-3"
           max-height="450px"
           max-width="400px"
           src="@/assets/imagens/logo-escrita.png"></v-img>
      </div>
      <div v-if="validatingToken == false">
          <div class="text-center ma-10">
              <div>
                    <v-btn
                    class="ma-2 center_button"
                    :loading="loading"
                    :disabled="loading"
                    @click="loader = 'loading'"
                    to="/login"
                    >
                    Login
                    </v-btn>
              </div>
              <div>
                    <v-btn
                    class="ma-2 center_button-cadastro"
                    :loading="loading2"
                    :disabled="loading2"
                    @click="loader = 'loading2'"
                    to="/register"
                    >
                   Cadastre-se
                    </v-btn>
                </div>
           </div>
      </div>
      <div v-if="validatingToken == true">
        <v-row class=" justify-center mx-auto">
          <v-progress-circular 
          :size="80"
          :width="8" style="margin-top:50px"
          color="purple" class="d-flex justify-center"
          indeterminate
          ></v-progress-circular>
      </v-row>
      </div>
    </div>
</v-app>
</template>

<script>
export default {
  props:{validatingToken: Boolean},
  name: 'Home',
  data(){
      return{
          loader: null,
          loading: false,
          loading2: false,
      }
  },
  components: { 
   
  },
  mounted() {
    document.title = 'Night App'
  },
  watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },

}
</script>

<style>
#home{ 
    /* The image used */
    background: 50% url(../assets/background-image-inicial.png) #120222;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.center_button{
    margin: 10px;
    width: 250px;
    background: #D356F3 !important;
    text-decoration: none;
    color:#fff !important;
    border: none;
    border-radius: 10px;
    height: 30px;
}
.center_button-cadastro{
  background-color: #450A88 !important;
  margin: 10px;
  width: 250px;
  text-decoration: none;
  border: none;
  color:#fff !important;
  border-radius: 10px;
  height: 30px;
}

</style>