import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/application/auth/pages/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path:'*', 
    redirect: '/', 
  },
  {
    path:'/register',
    name:'Register',
    component: () => import(/* webpackChunkName: "/register" */'@/application/auth/pages/Register'),
  },
  {
    path:'/login',
    name:'Login',
    component: () => import(/* webpackChunkName: "/login" */'@/application/auth/pages/Login'),
  },
  // {
  //   path: '/about',
  //   name: 'Sobre',
  //   component: () => import( '../views/About.vue'),
  //   meta:{requiresLogin : true}
  // },
  {
    path: '/bilheteria',
    name: 'Bilheteria',
    component: () => import( '@/application/app/pages/Bilheteria.vue'),
    meta:{requiresLogin : true}
  },
  {
    path: '/evento',
    name: 'Eventos',
    component: () => import('@/application/app/pages/Evento.vue'),
    meta:{requiresLogin : true}
  },
  {
    path: '/financeiro',
    name: 'Financeiro',
    component: () => import( '@/application/app/pages/Financeiro.vue'),
    meta:{requiresLogin : true}
  },
  {
    path: '/permuta',
    name: 'Permutas',
    component: () => import( '@/application/app/pages/Permutas.vue'),
    meta:{requiresLogin : true}
  },
  {
    path: '/restaurante',
    name: 'Restaurante',
    component: () => import( '@/application/app/pages/Restaurante.vue'),
    meta:{requiresLogin : true}
  },

  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: () => import( '../views/Dashboard.vue'),
  //   meta:{requiresLogin : true}
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const json = localStorage.getItem('token')
  const user = JSON.parse(json)
  if(to.matched.some(record => record.meta.requiresLogin)) {
    user ? next() : next({ path: '/' })
  }
  else {
    next()
  }
});
export default router
