import services from './services'
import Vue from 'vue'
import axios from 'axios'
import info from './serverinfo'
import store from '@/store'

export const http = axios.create({
  baseURL: 'https://pws.nightapp.me'
})

http.interceptors.request.use(config => {
  const userKey = localStorage.getItem('token')
  if (userKey) {
    const token  = JSON.parse(userKey)
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, error => {
  Promise.reject(error)
})

// Add a response interceptor
http.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config
  if (error.response.status === 401 && originalRequest.url === `${info.Host()}/register`) {
    return Promise.reject(error)
  } else if (error.response.status === 401) {
    if (!localStorage.getItem('userKey')) {
      store.dispatch('auth/ActionDoLogout')
      return Promise.reject(error)
    } else {
      store.dispatch('auth/ActionUpdateToken')
      Promise.resolve('Success')
    }
  } 
  return Promise.reject(error)
})


Vue.prototype.$axios = http

export const auth = services.auth
export const app = services.app